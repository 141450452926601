import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

const StyledTypography = styled(Typography)`
  font-size: 24vw;
  line-height: 0.8;

  ${breakpointsMedia({
    lg: css`
      font-size: 22vw;
    `,
  })}
`;

const StyledImage = styled(Image)`
  z-index: 1;
  position: relative;
`;

const StyledGrid = styled(Grid)`
  min-height: 100vh;


${breakpointsMedia({
    lg: css`
    min-height: 100%;
    `,
  })}
`;

function ErrorPage() {
  return (
    <Box
      tag="section"
      background="linear-gradient(180deg, #A318FF 0%, #7203BB 100%)"
    >
      <StyledGrid
        paddingRight="0"
        paddingLeft="0"
        gridTemplateRows={{ xs: '130px auto 1fr', lg: '150px auto 1fr' }}
        minHeight={{ xs: '100vh', md: '100%' }}
      >

        {/* TITLE & BUTTON */}

        <Grid.Child
          gridRow="2"
          gridColumn={{ xs: '1 / span 4', md: '2 / span 4', lg: '4 / span 6' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            paddingHorizontal={{ xs: '12x' }}
            alignItems="center"
            marginBottom={{ xs: '8x', lg: '0' }}
          >
            <Typography
              variant="heading1"
              color="white.default"
              intlKey="404_ERROR_PAGE.TITLE"
              marginBottom="8x"
              textAlign="center"
            />
            <Typography
              variant="heading3"
              color="white.default"
              intlKey="404_ERROR_PAGE.SUBTITLE"
              marginBottom={{ xs: '8x', md: '12x' }}
              textAlign="center"
              whiteSpace="pre-line"
            />

            <Button
              intlKey="404_ERROR_PAGE.BUTTON_LABEL"
              iconProps={{
                name: 'arrow-right',
                title: 'Da click para navegar a la pagina inicial',
              }}
              styleVariant="white"
              onClick={() => window.open('/', '_self')}
            />
          </Box>
        </Grid.Child>

        {/* ROBOT IMAGE */}

        <Grid.Child
          gridRow="3"
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 12' }}
          alignSelf="end"
          justifySelf="center"
        >
          <StyledImage
            srcSet={{
              xs: '404/robot-xs.png',
              lg: '404/robot-lg.png',
            }}
            alt="Ilustracion de un robot"
            maxWidth={{ xs: '100%', lg: '95%' }}
          />
        </Grid.Child>

        {/* BACK 404 TEXT */}

        <Grid.Child
          gridRow="3"
          gridColumn={{ xs: '1 / span 4', md: '1 / span 6', lg: '1 / span 12' }}
          alignSelf="end"
          justifySelf="center"
        >
          <StyledTypography
            intlKey="404_ERROR_PAGE.BACK_TEXT"
            color="#9436E1"
            strong
          />
        </Grid.Child>
      </StyledGrid>

    </Box>
  );
}

export default ErrorPage;
